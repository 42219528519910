import React, {useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {Alert, Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';

export default function UserInfoForm({ submitCallback, closeModal }) {
    const [hasError] = useState(false);
    const [hasSent] = useState(false);
    const [isSending, setSending] = useState(false);

    const handleSubmit = (formEvent) => {
        const form = formEvent.target;

        formEvent.preventDefault();
        formEvent.stopPropagation();

        if (form.checkValidity() === false || isSending) {
          return;
        }

        const formData = new FormData(form);
        const name = formData.get('name');
        const email = formData.get('email');
        const organization = formData.get('organization');

        setSending(true);

        try {
            fetch(
              "https://docs.google.com/forms/d/e/1FAIpQLSfPxiJ3EHJOE-CQxKR89OdcxnFOvGoapFhhy0JtBC8NMRJNPQ/formResponse?" +
                new URLSearchParams({
                  "entry.670094799": name,
                  "entry.148437047": email,
                  "entry.1207476146": organization,
                }),
              {
                mode: "no-cors",
              }
            );

            localStorage.setItem('userInfoSent', true);

          } catch (e) {
            console.log(e.message);
          }

          submitCallback();
      };

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Download Case Study</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <div className='UserInfoForm'>
                <Form onSubmit={handleSubmit}>

                    <Form.Group>
                        <Form.Label className="h5 mb-2">Name</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text>&nbsp;</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control required type="text" placeholder="Enter Name" name="name"/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="h5 mb-2">Email</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text>&nbsp;</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control required type="text" placeholder="Enter Email" name="email"/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group>
                        <Form.Label className="h5 mb-2">Organization</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                            <InputGroup.Text>&nbsp;</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control required type="text" placeholder="Enter Organization" name="organization"/>
                        </InputGroup>
                    </Form.Group>

                    <Row className="mt-md-5 my-sm-4">
                        <Col md={8} sm={12} className="mb-sm-4">
                            {hasSent && (
                            <Alert variant="success" className="alert-contact text-center">
                                <strong>
                                <FontAwesomeIcon icon={faPaperPlane}/>
                                &nbsp; Your information has been sent successfully
                                </strong>
                            </Alert>
                            )}

                            {hasError && (
                            <Alert variant="danger" className="alert-contact text-center">
                                <strong>
                                <FontAwesomeIcon icon={faExclamationCircle}/>
                                &nbsp; Error sending information
                                </strong>
                            </Alert>
                            )}

                        </Col>

                        <Col md={4} sm={12}>
                            <Button type="submit" className="btn-dark btn-block">Submit</Button>
                        </Col>
                    </Row>

                </Form>
            </div>
            </Modal.Body>


        </Modal>
    );
}
