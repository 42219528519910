import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import usdaScreen from '../../assets/images/usda/usda-screen.png';

import caseStudyPdf from '../../assets/documents/usda/case-study.pdf';

export default function PortfolioUsda() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#000000">

      <ResponsiveImage
        alt="Dashboard Created for USDA to manage Payroll Contacts"
        className="pb-5"
        image={usdaScreen}
      />

      <PortfolioDescription
        title="USDA: Technical Services Surge Support for the National Finance Center"
        description={"We delivered extensive modernization and technical services for critical financial and HR management systems. Key achievements included transforming the CLER application to enhance payroll processing efficiency, automating the Treasury Report on Receivables (TROR) for faster data processing, and resolving longstanding issues in the Document Production, Retrieval, and Storage (DPRS) system. These updates improved operational efficiency, compliance, and data accuracy, positioning the USDA NFC for continued innovation and success."
        }
        createdFor="USDA"
        createdForLink="https://www.usda.gov/"
        demo="Request Demo"
        demoLink='https://bnlconsulting.com/contact'
        demoLinkText='Contact Us'
        learnMoreLink="/case-studies/usda"
        learnMoreText="View Case Study"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="USDA-Case-Study"
      />

    </PortfolioSection>
  );
}
