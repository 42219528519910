import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight, faFileDownload} from '@fortawesome/free-solid-svg-icons';

import UserInfoForm from '../Common/UserInfo/UserInfoForm';

import './PortfolioDescription.css';

function PortfolioDescription(props) {
  const {
    title,
    description,
    learnMoreText,
    learnMoreLink,
    learnMoreDownloadLink,
    learnMoreDownloadName,
    demo,
    demoLink,
    demoLinkText,
    createdFor,
    createdForLink,
  } = props;

  const userInfoSent = localStorage.getItem('userInfoSent');

  const [openModal, setOpenModal] = useState(false);

  function createdForHtml() {
    if (createdForLink !== false) {
      return (<a href={createdForLink} target="_blank" rel="noopener noreferrer">{createdFor}</a>);
    }
    return (<p>{createdFor}</p>);
  }

  const downloadLearnMoreDocument = () => {
    setOpenModal(false);

    const downloadLinkEl = document.getElementById("hiddenLearnMoreLink");
    downloadLinkEl.click();
  }

  const openUserInfoForm = () => {
    setOpenModal(true);
  }

  const closeModal = () => {
    setOpenModal(false);
  }

  return (
    <div className="row justify-content-lg-center py-2">
      <div className="col-sm-8 pl-sm-5">
        <h3>{title}</h3>
        <p>{description}</p>

        <div className="btnSection">
          {learnMoreLink && (
            <a className="btn btn-primary" href={learnMoreLink}>
              <div>
                {learnMoreText} &nbsp; <FontAwesomeIcon className="right-chevron small" icon={faChevronRight}/>
              </div>
            </a>
          )}

          {!userInfoSent && learnMoreDownloadLink && learnMoreDownloadName && (
            <>
              <button
                className="btn btn-primary ml-2"
                onClick={openUserInfoForm}
                >
                <div>
                  <FontAwesomeIcon icon={faFileDownload}/>
                </div>
              </button>

              <a
                id="hiddenLearnMoreLink"
                className="hiddenLearnMoreDownload"
                href={learnMoreDownloadLink}
                download={learnMoreDownloadName}
                target="_blank"
                rel="noreferrer"
              >
              </a>
            </>
          )}

          {userInfoSent && learnMoreDownloadLink && learnMoreDownloadName && (
            <a
              className="btn btn-primary ml-2"
              href={learnMoreDownloadLink}
              download={learnMoreDownloadName}
              target="_blank"
              rel="noreferrer"
              aria-label={learnMoreDownloadName}
              >
              <div>
                <FontAwesomeIcon icon={faFileDownload}  aria-label={learnMoreDownloadName}/>
              </div>
            </a>
          )}
        </div>
      </div>

      <div className="col-sm-2 offset-sm-1">
        <div className="border-left pl-3 my-5 my-md-0 small font-weight-light">
          <strong>{demo}</strong>
          <br/>
          <a href={demoLink}>{demoLinkText}</a>
          <br/>
          <br/>
          <strong>Created For</strong>
          <br/>
          {createdForLink ? createdForHtml() : <p>{createdFor}</p>}
        </div>
      </div>

      {openModal && <UserInfoForm submitCallback={downloadLearnMoreDocument} closeModal={closeModal} />}

    </div>

  );
}

PortfolioDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  learnMoreText: PropTypes.node,
  learnMoreLink: PropTypes.string,
  learnMoreDownloadLink: PropTypes.string,
  learnMoreDownloadName: PropTypes.string,
  demo: PropTypes.node,
  demoLink: PropTypes.string,
  demoLinkText: PropTypes.string,
  createdForLink: PropTypes.string,
  createdFor: PropTypes.node
};

PortfolioDescription.defaultProps = {
  learnMoreText: "Learn More",
}

export default PortfolioDescription;
